// import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';

import Home from '@/components/Home/HomeComponent/HomeComponent.vue';
import Error404 from '@/components/Errors/Error404Component/Error404Component.vue';
import OurHistory from '@/components/Home/AboutUsComponent/HistoryComponent/HistoryComponent.vue';
import OurPhilosophy from '@/components/Home/AboutUsComponent/PhilosophyComponent/PhilosophyComponent.vue';
import OurFinalCustomers from '@/components/Home/OurFinalCustomersComponent/OurFinalCustomersComponent.vue';
import WannaSellYourFruit from '@/components/Home/WannaSellYourFruitComponent/WannaSellYourFruitComponent.vue';
import WannaWorkWithUs from '@/components/Home/WannaWorkWithUsComponent/WannaWorkWithUsComponent.vue';
import PresentationVideo from '@/components/Home/GalleryComponent/PresentationVideoComponent/PresentationVideoComponent.vue';
import CookiesPolicy from '@/components/CookiesPolicyComponent/CookiesPolicyComponent.vue';
import PrivacyNotice from '@/components/PrivacyNoticeComponent/PrivacyNoticeComponent.vue';
import CodeOfEthics from '@/components/CodeOfEthicsComponent/CodeOfEthicsComponent.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
        },
        {
            path: '/our-history',
            name: 'ourHistory',
            component: OurHistory,
        },
        {
            path: '/our-philosophy',
            name: 'ourPhilosophy',
            component: OurPhilosophy,
        },
        {
            path: '/our-final-customers',
            name: 'ourFinalCustomers',
            component: OurFinalCustomers,
        },
        {
            path: '/wanna-sell-your-fruit',
            name: 'wannaSellYourFruit',
            component: WannaSellYourFruit,
        },
        {
            path: '/wanna-work-with-us',
            name: 'wannaWorkWithUs',
            component: WannaWorkWithUs,
        },
        {
            path: '/cookies-policy',
            name: 'cookiesPolicy',
            component: CookiesPolicy,
        },
        {
            path: '/code-of-ethics',
            name: 'codeOfEthics',
            component: CodeOfEthics,
        },
        {
            path: '/presentation-video',
            name: 'presentationVideo',
            component: PresentationVideo,
        },
        {
            path: '/privacy-notice',
            name: 'privacyNotice',
            component: PrivacyNotice,
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'error404',
            component: Error404,
        }
    ],
    scrollBehavior(to, from, savedPosition) {

        let res = { left: 0, top: 0 }
        
        if (savedPosition) {
            res = savedPosition;
        }

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(res)
            }, 600)
        })
        
    },
});

export default router;
